import fusionwave from '/public/logos/circle/fusionwave.jpeg'
import biotech from '/public/logos/circle/biotech.jpg'
import nextBridge from '/public/logos/circle/next-bridge.png'
import winstore from '/public/logos/circle/winstore.jpg'
import orient from '/public/logos/circle/orient.jpg'

export const resume = [
  {
    index: 1,
    company: 'NextBridge',
    title: 'Senior Fullstack Engineer',
    logo: nextBridge,
    start: '2024',
    end: {
      label: 'Present',
      dateTime: new Date().getFullYear(),
    },
  },
  {
    index: 2,
    company: 'Fusionwave',
    title: 'Lead - Fullstack Engineer',
    logo: fusionwave,
    start: '2022',
    end: '2024',
  },
  {
    index: 3,
    company: 'Orient',
    title: 'Senior Fullstack - Library Maintainer',
    logo: orient,
    start: '2021',
    end: '2022',
  },
  {
    index: 4,
    company: 'Biotech',
    title: 'Software Development - Team Lead',
    logo: biotech,
    start: '2020',
    end: '2021',
  },
  {
    index: 5,
    company: 'Winstore.pk',
    title: 'Fullstack Mobile',
    logo: winstore,
    start: '2014',
    end: '2014',
  },
]
