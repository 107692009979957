import(/* webpackMode: "eager" */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/node_modules/react-wrap-balancer/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/public/avatar.jpg");
;
import(/* webpackMode: "eager" */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/public/avatarhover.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/src/components/JobList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpotifyPlayer"] */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/src/components/Spotify.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/syedammad/workspace/___p/portfolio-cloudflare-nextjs/src/components/ui/cards/CardHome.tsx");
